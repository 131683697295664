import { Component } from '@angular/core';
import { IMappingSetting } from 'src/app/interfaces/i-mapping-setting';
import { MappingService } from 'src/app/services/mapping.service';
import { OntologyService } from 'src/app/services/ontology.service';

@Component({
  selector: 'app-mapping-settings',
  templateUrl: './mapping-settings.component.html',
  styleUrls: ['./mapping-settings.component.scss'],
})
export class MappingSettingsComponent {
  settings: IMappingSetting[];
  firstTermIndex: number | undefined;
  currentPage = 0;
  totalTerms = 0;
  readonly ITEMS_PER_PAGE = 10;
  currentItemsPerPage = this.ITEMS_PER_PAGE;
  visibleTerms: IMappingSetting[] = [];

  constructor(
    public mappingService: MappingService,
    public ontologyService: OntologyService
  ) {
    this.settings = this.mappingService.settings;
    this.totalTerms = this.settings.length;
    this.onSelectedChange();
    this.onPageChange();
  }

  onSelectedChange() {
    let _firstTermIndex: number | undefined;
    this.settings.forEach((term, index) => {
      if (_firstTermIndex === undefined && term.includeInMapping) {
        _firstTermIndex = index;
      }
      if (
        this.mappingService.applySameOntologiesForEachTerm &&
        _firstTermIndex !== undefined &&
        term.includeInMapping
      ) {
        term.ontologies = this.settings[_firstTermIndex].ontologies;
      }
      if (!term.includeInMapping) {
        term.ontologies = [];
      }
    });
    this.firstTermIndex = _firstTermIndex;
  }

  onClearOntologies(visibleIndex: number) {
    const index = this.getIndex(visibleIndex);
    this.settings[index].ontologies = [];
    this.onSelectedChange();
  }

  onSelectAll(visibleIndex: number) {
    const index = this.getIndex(visibleIndex);
    this.settings[index].ontologies = this.ontologyService.availableOntologies.map(o => o.ontology_name);
    this.onSelectedChange();
  }

  isInputDisabled(visibleIndex: number): boolean {
    const index = this.getIndex(visibleIndex);
    return (
      (this.mappingService.applySameOntologiesForEachTerm && index !== this.firstTermIndex) ||
      !this.settings[index].includeInMapping
    );
  }

  showSameOntologyForEachCheckbox(visibleIndex: number): boolean {
    const index = this.getIndex(visibleIndex);
    return index === this.firstTermIndex && this.settings.length > this.firstTermIndex + 1;
  }

  onPageChange() {
    const firstTermIndex = this.currentPage * this.currentItemsPerPage;
    const lastTermIndex = firstTermIndex + this.currentItemsPerPage;
    this.visibleTerms = this.settings.slice(firstTermIndex, lastTermIndex);
  }

  private getIndex(visibleIndex: number): number {
    return visibleIndex + this.currentPage * this.currentItemsPerPage;
  }
}
