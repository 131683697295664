import { Injectable } from '@angular/core';
import { MappingType } from '../enums/mapping-type';
import { IDataSettings, IIncludeItem } from '../interfaces/i-data-settings';
import { IMappingSetting } from '../interfaces/i-mapping-setting';
import { IStepValidator } from '../interfaces/i-step-validator';
import { MappingSteps } from '../enums/mapping-steps';
import { IMappingResult } from '../interfaces/i-mapping-result';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMappingPayload } from '../interfaces/i-terminology-mapping-payload';
import { ITerminologyMapping as ITerminologyMapping } from '../interfaces/i-mapping-status';
import { IResultHeaders } from '../interfaces/i-result-headers';
import { IMappingOptions } from '../interfaces/i-mapping-options';
import { IFileSettings } from '../interfaces/i-file-settings';
import { IFileUploadResponse } from '../interfaces/i-file-upload-response';
import { OntologyService } from './ontology.service';

@Injectable({
  providedIn: 'root',
})
export class MappingService {
  private initializedSubject = new BehaviorSubject<boolean>(false);
  initialized$ = this.initializedSubject.asObservable();
  private _type!: MappingType;
  private _dataSettings!: IDataSettings;
  private _fileSettings!: IFileSettings;
  private _applySameOntologiesForEachTerm = false;
  private _settings!: IMappingSetting[];
  private _options!: IMappingOptions;
  private ontologiesInitialized = false;
  private _mappingMethodologies!: string[];
  private _resultHeaders: IResultHeaders = {};

  public static readonly MAX_TERMS_COUNT = 1000;
  public static readonly MAX_UNIQUE_TERMS_FOR_COLUMN_MAPPING = 50000;
  public readonly DEFAULT_MAX_RESULTS_PER_ONTOLOGY = 5;
  public readonly DEFAULT_SIMILARITY_SCORE_THRESHOLD = 0.1;

  constructor(
    private api: ApiService,
    private ontologyService: OntologyService
  ) {
    this.initialize();
    this.ontologyService.initialized$.subscribe({
      next: initialized => {
        this.ontologiesInitialized = initialized;
        this.isInitComplete();
      },
      error: err => this.initializedSubject.error(err),
    });
  }

  private initialize() {
    this.initializedSubject.next(false);
    this.fetchMethodologiesAndResultHeaders();
  }

  reset() {
    this.type = MappingType.UPLOAD_FILE;
    this.resetDataSettings();
    this.resetFileSettings();
    this.applySameOntologiesForEachTerm = false;
  }

  get type(): MappingType {
    return this._type;
  }

  set type(mappingType) {
    this._type = mappingType;
    this.resetOptions();
  }

  get mappingMethodologies(): string[] {
    return this._mappingMethodologies;
  }

  get resultHeaders(): IResultHeaders {
    return this._resultHeaders;
  }

  get dataSettings(): IDataSettings {
    return this._dataSettings;
  }

  set dataSettings(newSettings: IDataSettings) {
    this._dataSettings = newSettings;
    this.settings = [];
    this.dataSettings.terms.forEach(term => {
      this.settings.push({
        termOrColumnName: term,
        ontologies: [],
        includeInMapping: true,
      });
    });
  }

  private resetDataSettings() {
    if (this.initializedSubject.value) {
      this.dataSettings = {
        terms: [],
      };
    }
  }

  get fileSettings() {
    return this._fileSettings;
  }

  set fileSettings(file: IFileSettings) {
    this._fileSettings = file;
    this.settings = [];
    this.fileSettings.fileData?.data.forEach(column => {
      this.settings.push({
        termOrColumnName: column.columnHeader,
        uniqueTermsInColumnCount: column.terms.length,
        includeInMapping: column.ontologies.length > 0 ? true : false,
        ontologies: column.ontologies,
      });
    });
  }

  private resetFileSettings() {
    this.fileSettings = {};
  }

  get mappingOptions(): IMappingOptions {
    return this._options;
  }

  set mappingOptions(options: IMappingOptions) {
    this._options = options;
  }

  private resetOptions() {
    if (this.initializedSubject.value) {
      const methodology = this.mappingMethodologies[0];
      const maxResultsPerOntology: number =
        this.type === MappingType.INDIVIDUAL_TERMS ? this.DEFAULT_MAX_RESULTS_PER_ONTOLOGY : 1;
      this.mappingOptions = {
        mappingMethodology: methodology,
        maxResultsPerOntology,
      };
    }
  }

  get applySameOntologiesForEachTerm(): boolean {
    return this._applySameOntologiesForEachTerm;
  }

  set applySameOntologiesForEachTerm(value: boolean) {
    this._applySameOntologiesForEachTerm = value;
  }

  get settings(): IMappingSetting[] {
    return this._settings;
  }

  set settings(mappingSettings: IMappingSetting[]) {
    this._settings = mappingSettings;
  }

  getIncludeItems(methodology: string, mappingType: MappingType): IIncludeItem[] {
    const includeList: IIncludeItem[] = [];
    const excludeFileColumnHeaders = ['query term', 'ontology name'];
    this.resultHeaders[methodology].forEach(header => {
      if (!(mappingType === MappingType.UPLOAD_FILE && excludeFileColumnHeaders.includes(header))) {
        includeList.push({
          label: header,
          included: true,
        });
      }
    });
    return includeList;
  }

  isStepValid(stepIndex: number): IStepValidator {
    switch (stepIndex) {
      case MappingSteps.SELECT_DATA_SETTINGS: {
        switch (this.type) {
          case MappingType.INDIVIDUAL_TERMS: {
            if (this.dataSettings.terms.length === 0) {
              return {
                dataVaild: false,
                error: 'No Search Terms defined. Cannot proceed',
              };
            } else if (this.dataSettings.terms.length > MappingService.MAX_TERMS_COUNT) {
              return {
                dataVaild: false,
                error: 'Max terms exceeded. Please remove Terms to continue',
              };
            } else {
              return {
                dataVaild: true,
              };
            }
          }
          case MappingType.UPLOAD_FILE: {
            if (
              this.fileSettings.fileData?.data &&
              this.fileSettings.fileData.data.length > 0 &&
              this.fileSettings.fileUrl
            ) {
              return {
                dataVaild: true,
              };
            } else {
              return {
                dataVaild: false,
                error: 'No File has been loaded. Cannot proceed.',
              };
            }
          }
          default: {
            return {
              dataVaild: true,
            };
          }
        }
      }
      case MappingSteps.SELECT_ONTOLOGY: {
        if (this.settings.findIndex(column => column.includeInMapping === true) === -1) {
          return {
            dataVaild: false,
            error: 'Please include at least 1 column to continue',
          };
        }
        let dataValid = true;
        let error: string | undefined;
        let totalUniqueTerms = 0;
        this.settings.forEach(mapping => {
          if (mapping.includeInMapping && mapping.uniqueTermsInColumnCount) {
            totalUniqueTerms += mapping.uniqueTermsInColumnCount;
          }
          if (mapping.includeInMapping && mapping.ontologies.length === 0) {
            dataValid = false;
            error = 'Please select at least 1 ontology for each item to continue';
          }
        });
        if (totalUniqueTerms > MappingService.MAX_UNIQUE_TERMS_FOR_COLUMN_MAPPING) {
          dataValid = false;
          error =
            totalUniqueTerms +
            '/' +
            MappingService.MAX_UNIQUE_TERMS_FOR_COLUMN_MAPPING +
            ' Maximum Unique Terms. Please remove columns to continue';
        }
        return {
          dataVaild: dataValid,
          error,
        };
      }
      default:
        return {
          dataVaild: true,
        };
    }
  }

  uploadFile(file: File): Observable<IFileUploadResponse> {
    return this.api.uploadFile(file);
  }

  mapToOntologies(): Observable<ITerminologyMapping> {
    const payload: IMappingPayload = {
      task_name: this.mappingOptions.mappingMethodology,
      terms_input_type: this.type,
      params: {
        limit: this.mappingOptions.maxResultsPerOntology,
        similarity_score_threshold: this.mappingOptions.similarityScoreThreshold,
      },
      label: this.mappingOptions.jobTitle,
    };
    switch (this.type) {
      case MappingType.INDIVIDUAL_TERMS: {
        const mappingTerms: { term: string; ontologies: string[] }[] = [];
        this.settings.forEach(term => {
          if (term.includeInMapping) {
            mappingTerms.push({
              term: term.termOrColumnName,
              ontologies: term.ontologies,
            });
          }
        });
        payload.params.terms = mappingTerms;
        return this.api.submit(payload);
      }
      case MappingType.UPLOAD_FILE: {
        const termColumns: { column_header: string; ontologies: string[] }[] = [];
        this.settings.forEach(column => {
          if (column.includeInMapping) {
            termColumns.push({
              column_header: column.termOrColumnName,
              ontologies: column.ontologies,
            });
          }
        });
        payload.params.term_columns = termColumns;
        payload.params.file_url_path = this.fileSettings.fileUrl;
        return this.api.submit(payload);
      }
    }
  }

  getMapping(runId: string): Observable<ITerminologyMapping> {
    return this.api.getMapping(runId);
  }

  getTaskRequest(taskId: string): Observable<IMappingPayload> {
    return this.api.getTaskRequest(taskId);
  }

  getResult(runId: string): Observable<IMappingResult> {
    return this.api.getResult(runId);
  }

  cancelTask(runId: string): Observable<ITerminologyMapping> {
    return this.api.cancelTask(runId);
  }

  private fetchMethodologiesAndResultHeaders() {
    this.api.fetchMappingMethodologies().subscribe({
      next: methodologies => {
        this._mappingMethodologies = methodologies;
        this.mappingMethodologies.forEach(type => {
          this.fetchResultHeaders(type);
        });
      },
      error: err => this.initializedSubject.error(err),
    });
  }

  private fetchResultHeaders(methodology: string) {
    this.api.fetchResultHeaders(methodology).subscribe({
      next: resultHeaders => {
        this._resultHeaders[methodology] = resultHeaders;
        this.isInitComplete();
      },
      error: err => this.initializedSubject.error(err),
    });
  }

  private isInitComplete() {
    const complete =
      this.ontologiesInitialized &&
      this.mappingMethodologies &&
      this.mappingMethodologies.length > 0 &&
      this.resultHeaders &&
      Object.keys(this.resultHeaders).length === this.mappingMethodologies.length;
    this.initializedSubject.next(complete);
    if (complete) {
      this.reset();
    }
  }

  formatResultsToIgxGrid(results: IMappingResult): unknown[] {
    const returnArray: unknown[] = [];
    results.data.forEach(value => {
      const gridRow: {
        [key: string]: string;
      } = {};
      results.header.forEach((column, index) => {
        if (['synonyms', 'info'].includes(column.toLowerCase())) {
          value[index] = value[index]?.split('|').join(',\n');
        }
        gridRow[column] = value[index];
      });
      returnArray.push(gridRow);
    });
    return returnArray;
  }
}
