<div class="width-fit min-width-5 margin-inline-auto">
  <div
    [class.grid-3-columns]="mappingService.type === 'filecolumns'"
    [class.grid-2-columns]="mappingService.type === 'individual'"
    class="row-gap-1 column-gap-1">
    <div *ngIf="mappingService.type === 'individual'" class="text-center header-1">Term</div>
    <div *ngIf="mappingService.type === 'filecolumns'" class="text-center header-1">
      Column Header<br />(Total Unique Terms)
    </div>
    <div *ngIf="mappingService.type === 'filecolumns'" class="text-center header-1">Include In Mapping</div>
    <div class="text-center header-1">Map to Output</div>
    <ng-container *ngFor="let term of visibleTerms; let index = index">
      <div class="text-center" [class.text-selected]="mappingService.type === 'filecolumns' && term.includeInMapping">
        {{ term.termOrColumnName }} {{ term.uniqueTermsInColumnCount ? '(' + term.uniqueTermsInColumnCount + ')' : '' }}
      </div>
      <div *ngIf="mappingService.type === 'filecolumns'" class="text-center">
        <nb-checkbox [(checked)]="term.includeInMapping" (checkedChange)="onSelectedChange()"></nb-checkbox>
      </div>
      <div class="margin-inline-auto">
        <ng-select
          [(ngModel)]="term.ontologies"
          [multiple]="true"
          [disabled]="isInputDisabled(index)"
          (change)="onSelectedChange()"
          [placeholder]="term.includeInMapping ? 'Select Ontologies' : '--'"
          appearance="outline"
          [maxSelectedItems]="mappingService.type === 'filecolumns' ? 3 : ontologyService.availableOntologies.length">
          <ng-option *ngFor="let ontology of ontologyService.availableOntologies" [value]="ontology.ontology_name">
            {{ ontology.ontology_name }}
          </ng-option>
          <ng-template *ngIf="mappingService.type === 'individual'" ng-footer-tmp>
            <div class="text-center">
              <button nbButton status="primary" shape="round" outline size="tiny" (click)="onSelectAll(index)">
                Select All
              </button>
              <button
                nbButton
                status="primary"
                shape="round"
                outline
                size="tiny"
                (click)="onClearOntologies(index)"
                class="margin-left-1">
                Clear All
              </button>
            </div>
          </ng-template>
        </ng-select>
        <div>
          <nb-checkbox
            class="margin-block-auto"
            *ngIf="showSameOntologyForEachCheckbox(index)"
            [(checked)]="mappingService.applySameOntologiesForEachTerm"
            (checkedChange)="onSelectedChange()">
            Select this Ontology for all
          </nb-checkbox>
        </div>
      </div>
    </ng-container>
  </div>
  <igx-paginator
    *ngIf="totalTerms > currentItemsPerPage"
    [selectOptions]="[ITEMS_PER_PAGE, ITEMS_PER_PAGE * 2]"
    [(perPage)]="currentItemsPerPage"
    class="margin-top-1"
    [totalRecords]="totalTerms"
    [(page)]="currentPage"
    (pageChange)="onPageChange()"
    (perPageChange)="onPageChange()"></igx-paginator>
</div>
